(function () {
  "use strict";

  /* ========  mobile menu  start ========= */
  const menuWrapper = document.querySelector(".menu-wrapper");
  const body = document.querySelector("body");
  document.querySelector(".navbarOpen").addEventListener("click", () => {
    menuWrapper.classList.remove("ak");
    body.classList.add("a1v");
  });
  document.querySelector(".navbarClose").addEventListener("click", () => {
    menuWrapper.classList.add("ak");
    body.classList.remove("a1v");
  });

  // === close navbar-collapse when a  clicked
  document.querySelectorAll(".navbar li:not(.submenu-item) a").forEach((e) =>
    e.addEventListener("click", () => {
      menuWrapper.classList.add("ak");
      body.classList.remove("a1v");
    })
  );

  // === Sub-menu
  const submenuItems = document.querySelectorAll(".submenu-item");
  submenuItems.forEach((el) => {
    el.querySelector("a").addEventListener("click", () => {
      el.querySelector("a").classList.toggle("active");
      el.querySelector(".submenu").classList.toggle("ak");
    });
  });

  /* ========  mobile menu end ========= */

  window.onscroll = function () {
    // ===  Sticky Navbar
    const header = document.querySelector(".navbar");
    if (window.pageYOffset >= 100) {
      header.classList.add("sticky-navbar");
    } else {
      header.classList.remove("sticky-navbar");
    }

    // ===  show or hide the back-top-top button
    const backToTop = document.querySelector(".back-to-top");
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      backToTop.style.display = "flex";
    } else {
      backToTop.style.display = "none";
    }
  };

  /* ========  themeSwitcher start ========= */

  // themeSwitcher
  const themeSwitcher = document.getElementById("themeSwitcher");

  // Theme Vars
  const userTheme = localStorage.getItem("theme");
  const systemTheme = window.matchMedia("(prefers-color0scheme: dark)").matches;

  // Initial Theme Check
  const themeCheck = () => {
    if (userTheme === "dark" || (!userTheme && systemTheme)) {
      document.documentElement.classList.add("a4r");
      return;
    }
  };

  // Manual Theme Switch
  const themeSwitch = () => {
    if (document.documentElement.classList.contains("a4r")) {
      document.documentElement.classList.remove("a4r");
      localStorage.setItem("theme", "light");
      return;
    }

    document.documentElement.classList.add("a4r");
    localStorage.setItem("theme", "dark");
  };

  // call theme switch on clicking buttons
  themeSwitcher.addEventListener("click", () => {
    themeSwitch();
  });

  // invoke theme check on initial load
  themeCheck();
  /* ========  themeSwitcher End ========= */

  // ============ WOW js activation
  const wow = new WOW({ mobile: false });
  wow.init();

  /* ========  scroll to top  start ========= */
  function scrollTo(element, to = 0, duration = 500) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;

      const val = Math.easeInOutQuad(currentTime, start, change, duration);

      element.scrollTop = val;

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  document.querySelector(".back-to-top").onclick = () => {
    scrollTo(document.documentElement);
  };
  /* ========  scroll to top  end ========= */

  // ==== for menu scroll
  const pageLink = document.querySelectorAll(".menu-scroll");

  pageLink.forEach((elem) => {
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(elem.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
        offsetTop: 1 - 60,
      });
    });
  });

  // section menu active
  function onScroll(event) {
    const sections = document.querySelectorAll(".menu-scroll");
    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;

    for (let i = 0; i < sections.length; i++) {
      const currLink = sections[i];
      const val = currLink.getAttribute("href");
      const refElement = document.querySelector(val);
      const scrollTopMinus = scrollPos + 73;
      if (
        refElement.offsetTop <= scrollTopMinus &&
        refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
      ) {
        document.querySelector(".menu-scroll").classList.remove("active");
        currLink.classList.add("active");
      } else {
        currLink.classList.remove("active");
      }
    }
  }

  window.document.addEventListener("scroll", onScroll);

  GLightbox({
    href: "https://www.youtube.com/watch?v=ta5LXQbO1Jw",
    type: "video",
    source: "youtube",
    width: 900,
    autoplayVideos: true,
  });

  const contactForm = window.document.getElementById("contact-us-form");
  const btnContainer = window.document.getElementById("btn-container");
  const submitBtn = window.document.getElementById("submit-btn");

  contactForm.addEventListener("submit", (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    submitBtn.innerText = "Sending...";

    fetch("https://send-email.frogcp.workers.dev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, message }),
    })
      .then((res) => {
        if (res.status === 202 || res.status === 200) {
          btnContainer.innerHTML = `<h3 class="a1U a2o a2e aT dark:aV sm:a1A[22px] xl:a1A[26px]">
                    Thanks for contacting us. We'll contact you shortly.
                  </h3>`;
        }
      })
      .catch((err) => {
        submitBtn.innerText = "Contact Us";
        console.error(err);
      });
  });
})();
